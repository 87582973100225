@charset "UTF-8";

.m-slider-wrapper{
  flex: 1;

  ~ .l-content{
    flex: 0;
  }
}

.m-slider {
  @extend %clearfix;

  position: relative;
  overflow: hidden;
  padding: 15px 0 80px 0;
  max-height: calc(100vh - 300px);
  transition: 0.6s height linear;
  user-select: none;

  @include media(sm) {
    padding: 30px 0 0 0;
    min-height: 330px;
    max-height: 100vh;
    height: calc(100vh - 220px);
  }
  @include media(md) {
    height: calc(100vh - 240px);
  }

  &.init {
    max-height: 100vh;
  }
}

.m-slider-vertical,
.m-slider-horizontal {
  position: relative;
  float: left;
  overflow: hidden;
  width: 50%;
  height: 100%;
}

.m-slider-vertical {
  float: none;
  width: 100%;
  transition: 0.6s height linear;

  @include media(sm){
    transition: none;
    float: left;
    width: 50%;
  }
}

.m-slider-horizontal {
  display: none;

  @include media(sm){
    display: block;
  }
}

$horizontalAnimDur: 0.4;
$horizontalAnimDelay: 0.2;

$verticalAnimDur: $horizontalAnimDur*2+$horizontalAnimDelay*2+0.2;

.m-slider-vertical-track {
  height: 100%;
  transition: 0.8s transform ease;

  @include media(sm){
    transition: #{$verticalAnimDur}s transform ease;
  }
}

.m-slider-horizontal-track {
  height: 100%;
  transition: #{$horizontalAnimDur}s transform linear #{$horizontalAnimDelay}s;
}

.m-slider-vertical-slide{
  transition: 0.4s opacity linear;

  @include media(sm){
    transition: #{$verticalAnimDur/2}s opacity linear;
  }
}

.m-slider-horizontal-slide{
  padding-right: 20px;
  transition: 1s opacity linear;
}

.m-slider-slide {
  font-size: 0;
  opacity: 0;

  @include media(sm){
    height: 100%;
  }

  &.-hidden{
    display: none;
  }

  &.-active{
    display: block;
    opacity: 1;
  }

  &:after {
    display: none;
    visibility: hidden;
    height: 100%;
    content: ' ';
    vertical-align: middle;

    @include media(sm){
      display: inline-block;
      *display: inline;
      *zoom: 1;
    }
  }
}

.m-slider-slide-desc {
  padding-right: 0;
  width: 100%;
  vertical-align: middle;
  font-size: 12px;

  @include media(sm){
    display: inline-block;
    *display: inline;
    *zoom: 1;
  }
  @include media(sm) {
    padding-right: 40px;
    text-align: right;
    font-size: 11px;
  }
  @include media(md) {
    padding-right: 60px;
    font-size: 14px;
  }
  @include media(lg) {
    padding-right: 80px;
    font-size: 16px;
  }

  > .header {
    margin-bottom: 5px;
    color: #212121;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Ubuntu', sans-serif;

    @include media(sm){
      margin-bottom: 20px;
      font-size: 2.05em;
    }

    h1{
      margin: 0;
      font: inherit;
      line-height: 1.3;
    }

    p {
      margin: 0;
      line-height: 1.3;
    }

    .blue {
      color: color(blue);
    }

    .black {
      color: #212121;
    }
  }

  > .text {
    p {
      margin: 0;
      color: #666;
      font-size: 13px;
      line-height: 1.384615385;

      @include media(sm){
        line-height: 1.6;
      }
      @include media(md) {
        font-size: 13px;
      }
      @include media(lg) {
        font-size: 14px;
      }
    }

    .e-btn {
      margin-top: 1.87em;
    }
  }
}

.m-slider-slide-image {
  @extend %inlineblock;

  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.m-slider-slide-right-text{
  font-size: 14px;
}

// Controls..

.m-slider-dots {
  position: absolute;
  right: 0;
  bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 0;

  @include media(sm){
    right: 0;
    bottom: 50%;
    width: auto;
    transform: translate(0, 50%);
  }
}

.m-slider-dots-dot {
  display: inline-block;
  margin: 0 2px;
  padding: 10px 4px;
  border: 0;
  background: transparent;
  transition: 0.3s ease;

  @include media(sm){
    display: block;
    margin: 7px -5px 7px 0;
    padding: 7px 5px;
  }

  &.-active {
    &::before {
      border-color: color(blue);
      background: color(blue);
    }
  }

  &:hover {
    @include media(md){
      &::before {
        border-color: color(blue);
        background: color(blue);
      }
    }
  }

  &:focus {
    outline: none;
  }

  &::before {
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid #404040;
    border-radius: 50%;
    content: ' ';
  }
}

.e-slider-next-btn {
  @include i-icon(arrow-down);

  display: block;
  color: color(blue);
  text-align: center;
  text-decoration: none;

  &:hover {
    color: #212121;
    text-decoration: none;
  }

  &:before {
    font-size: 16px;
    transition: 0.3s color ease;
  }
}

// ..Controls
