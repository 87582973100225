@charset "UTF-8";


%btn{
  display: inline-block;
  line-height: 1.2;
  padding: 8px 10px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  font-size: 12px;
  transition: .3s ease;
  text-align: center;
  text-decoration: none;
  min-width: 165px;
}

.e-btn{
  @extend %btn;
  color: #212121;

  &:hover{
    color: color(blue);
  }

  &:focus{
    outline: none;
  }

  &.-filled{
    color: #fff;
    background: color(blue);
    border-color: color(blue);
    transition-property: background, border-color;

    &:hover{
      color: #fff;
      background: #212121;
      border-color: #212121;
    }
  }
}
