@charset "UTF-8";

.m-modal{
  position: relative;
  margin: auto;
  background: #fff;
  max-width: 555px;
  padding: 20px 25px;

  @include media(sm){
    padding: 60px 65px;
  }

  > p {
    margin: 0;
  }
}

.m-modal_close{
  @include i-icon(cross);

  width: auto;
  height: auto;
  position: absolute;
  font-size: 14px;
  padding: 10px;
  line-height: 1;
  opacity: 1;
  right: 0;
  top: 0;
  color: #bababa;
  transition: .3s color ease;

  @include media(sm){
    padding: 20px;
    font-size: 15px;
  }

  &:hover{
    color: #212121;
  }
}
