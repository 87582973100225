.pagination{
  margin-top: 20px;
  display: block;
  overflow: hidden;

  .counter{
    display: none;
  }

  ul{
    display: inline-block;
    & > li{
      float: left;
      padding: 3px 10px;
      list-style: none;
      margin: 0;
    }
  }
}