@charset "UTF-8";

// base styles

$selectize-font-family: inherit;
$selectize-font-smoothing: inherit;
$selectize-font-size: 14px;
$selectize-line-height: 18px;

$selectize-color-text: #212121;
$selectize-color-border: #e0e0e0;
$selectize-color-highlight: none;
$selectize-color-input: #fff;
$selectize-color-input-full: none;
$selectize-color-disabled: #fafafa;
$selectize-color-item: #f2f2f2;
$selectize-color-item-text: $selectize-color-text;
$selectize-color-item-border: #e0e0e0;
$selectize-color-item-active: #e0e0e0;
$selectize-color-item-active-text: $selectize-color-text;
$selectize-color-item-active-border: #e0e0e0;
$selectize-color-dropdown: #fff;
$selectize-color-dropdown-border: $selectize-color-border;
$selectize-color-dropdown-border-top: #e0e0e0;
$selectize-color-dropdown-item-active: color(blue);
$selectize-color-dropdown-item-active-text: #fff;
$selectize-color-dropdown-item-create-text: rgba(red($selectize-color-text), green($selectize-color-text), blue($selectize-color-text), 0.5);
$selectize-color-dropdown-item-create-active-text: $selectize-color-dropdown-item-active-text;
$selectize-color-optgroup: $selectize-color-dropdown;
$selectize-color-optgroup-text: $selectize-color-text;
$selectize-lighten-disabled-item: 30%;
$selectize-lighten-disabled-item-text: 30%;
$selectize-lighten-disabled-item-border: 30%;
$selectize-opacity-disabled: 0.5;

$selectize-shadow-input: none;
$selectize-shadow-input-focus: none;
$selectize-border: 0;
$selectize-dropdown-border: 0;
$selectize-border-radius: 0;

$selectize-width-item-border: 0;
$selectize-max-height-dropdown: 200px;

$selectize-padding-x: 10px;
$selectize-padding-y: 5px;
$selectize-padding-item-x: 6px;
$selectize-padding-item-y: 2px;
$selectize-padding-dropdown-item-x: $selectize-padding-x;
$selectize-padding-dropdown-item-y: $selectize-padding-y;
$selectize-margin-item-x: 3px;
$selectize-margin-item-y: 3px;

$selectize-arrow-size: 5px;
$selectize-arrow-color: #808080;
$selectize-arrow-offset: 15px;

$selectize-caret-margin: 0 2px 0 0;
$selectize-caret-margin-rtl: 0 4px 0 -2px;

@mixin selectize-border-radius ($radii) {
  -webkit-border-radius: $radii;
  -moz-border-radius: $radii;
  border-radius: $radii;
}
@mixin selectize-unselectable () {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@mixin selectize-box-shadow ($shadow) {
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}
@mixin selectize-box-sizing ($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing: $type;
  box-sizing: $type;
}
@mixin selectize-vertical-gradient ($color-top, $color-bottom) {
  background-color: mix($color-top, $color-bottom, 60%);
  background-image: -moz-linear-gradient(top, $color-top, $color-bottom); // FF 3.6+
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($color-top), to($color-bottom)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, $color-top, $color-bottom); // Safari 5.1+, Chrome 10+
  background-image: -o-linear-gradient(top, $color-top, $color-bottom); // Opera 11.10
  background-image: linear-gradient(to bottom, $color-top, $color-bottom); // Standard, IE10
  background-repeat: repeat-x;
}

.selectize-control {
  position: relative;
}

.selectize-dropdown, .selectize-input, .selectize-input input {
  color: $selectize-color-text;
  font-family: $selectize-font-family;
  //font-size: $selectize-font-size;
  line-height: $selectize-line-height;
  -webkit-font-smoothing: $selectize-font-smoothing;
}

.selectize-input, .selectize-control.single .selectize-input.input-active {
  cursor: text;
  display: inline-block;
}

.selectize-input {
  border: $selectize-border;
  padding: $selectize-padding-y $selectize-padding-x;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  @include selectize-box-sizing(border-box);
  @include selectize-box-shadow($selectize-shadow-input);
  @include selectize-border-radius($selectize-border-radius);

  .selectize-control.multi &.has-items {
    $padding-x: $selectize-padding-x;
    $padding-top: $selectize-padding-y - $selectize-padding-item-y - $selectize-width-item-border;
    $padding-bottom: $selectize-padding-y - $selectize-padding-item-y - $selectize-margin-item-y - $selectize-width-item-border;
    padding: $padding-top $padding-x $padding-bottom;
  }

  &.full {
    background-color: $selectize-color-input-full;
  }
  &.disabled, &.disabled * {
    cursor: default !important;
  }
  &.focus {
    @include selectize-box-shadow($selectize-shadow-input-focus);
  }
  &.dropdown-active {
    @include selectize-border-radius($selectize-border-radius $selectize-border-radius 0 0);
  }

  > * {
    vertical-align: middle;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
  }
  .selectize-control.multi & > div {
    cursor: pointer;
    margin: 0 $selectize-margin-item-x $selectize-margin-item-y 0;
    padding: $selectize-padding-item-y $selectize-padding-item-x;
    background: $selectize-color-item;
    color: $selectize-color-item-text;
    border: $selectize-width-item-border solid $selectize-color-item-border;

    &.active {
      background: $selectize-color-item-active;
      color: $selectize-color-item-active-text;
      border: $selectize-width-item-border solid $selectize-color-item-active-border;
    }
  }
  .selectize-control.multi &.disabled > div {
    &, &.active {
      color: lighten(desaturate($selectize-color-item-text, 100%), $selectize-lighten-disabled-item-text);
      background: lighten(desaturate($selectize-color-item, 100%), $selectize-lighten-disabled-item);
      border: $selectize-width-item-border solid lighten(desaturate($selectize-color-item-border, 100%), $selectize-lighten-disabled-item-border);
    }
  }
  > input {
    &::-ms-clear {
      display: none;
    }
    display: inline-block !important;
    padding: 0 !important;
    min-height: 0 !important;
    max-height: none !important;
    max-width: 100% !important;
    margin: $selectize-caret-margin;
    text-indent: 0 !important;
    border: 0 none !important;
    background: none !important;
    line-height: inherit !important;
    -webkit-user-select: auto !important;
    @include selectize-box-shadow(none);
    &:focus { outline: none !important; }
  }
}

.selectize-input::after {
  content: ' ';
  display: block;
  clear: left;
}

.selectize-input.dropdown-active::before {
  content: ' ';
  display: block;
  position: absolute;
  background: $selectize-color-dropdown-border-top;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
}

.selectize-dropdown {
  position: absolute;
  z-index: 10;
  border: $selectize-dropdown-border;
  background: $selectize-color-dropdown;
  margin: -1px 0 0 0;
  border-top: 0 none;
  @include selectize-box-sizing(border-box);
  @include selectize-border-radius(0 0 $selectize-border-radius $selectize-border-radius);

  [data-selectable] {
    cursor: pointer;
    overflow: hidden;
    .highlight {
    }
  }
  [data-selectable], .optgroup-header {
    padding: $selectize-padding-dropdown-item-y $selectize-padding-dropdown-item-x;
  }
  .optgroup:first-child .optgroup-header {
    border-top: 0 none;
  }
  .optgroup-header {
    color: $selectize-color-optgroup-text;
    background: $selectize-color-optgroup;
    cursor: default;
  }
  &.-open .active {
    background-color: $selectize-color-dropdown-item-active;
    color: $selectize-color-dropdown-item-active-text;
    &.create {
      color: $selectize-color-dropdown-item-create-active-text;
    }
  }
  .create {
    color: $selectize-color-dropdown-item-create-text;
  }
}

.selectize-dropdown-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: $selectize-max-height-dropdown;
}

.selectize-control.single .selectize-input {
  &, input { cursor: pointer; }
  &.input-active, &.input-active input { cursor: text; }

  //&:after {
  //  content: ' ';
  //  display: block;
  //  position: absolute;
  //  top: 50%;
  //  right: $selectize-arrow-offset;
  //  margin-top: round((-1 * $selectize-arrow-size / 2));
  //  width: 0;
  //  height: 0;
  //  border-style: solid;
  //  border-width: $selectize-arrow-size $selectize-arrow-size 0 $selectize-arrow-size;
  //  border-color: $selectize-arrow-color transparent transparent transparent;
  //}
  //&.dropdown-active:after {
  //  margin-top: $selectize-arrow-size * -0.8;
  //  border-width: 0 $selectize-arrow-size $selectize-arrow-size $selectize-arrow-size;
  //  border-color: transparent transparent $selectize-arrow-color transparent;
  //}
}

.selectize-control.rtl {
  &.single .selectize-input:after {
    left: $selectize-arrow-offset;
    right: auto;
  }
  .selectize-input > input {
    margin: $selectize-caret-margin-rtl !important;
  }
}

.selectize-control .selectize-input.disabled {
  opacity: $selectize-opacity-disabled;
  background-color: $selectize-color-disabled;
}

// default
$selectize-color-item: #1da7ee;
$selectize-color-item-text: #fff;
$selectize-color-item-active-text: #fff;
$selectize-color-item-border: #0073bb;
$selectize-color-item-active: #92c836;
$selectize-color-item-active-border: #00578d;
$selectize-width-item-border: 1px;
$selectize-caret-margin: 0 1px;

.selectize-control {
  &.multi {
    .selectize-input {
      &.has-items {
        $padding-x: $selectize-padding-x - 3px;
        padding-left: $padding-x;
        padding-right: $padding-x;
      }
      &.disabled [data-value] {
        color: #999;
        text-shadow: none;
        background: none;
        box-shadow: none;

        &, .remove {
          border-color: #e6e6e6;
        }
        .remove {
          background: none;
        }
      }
      [data-value] {
        text-shadow: 0 1px 0 rgba(0,51,83,0.3);
        &.active {
        }
      }
    }
  }
  &.single {
    .selectize-input {
    }
  }
}

.selectize-control.single .selectize-input, .selectize-dropdown.single {
}

.selectize-dropdown {
  .optgroup-header {
    padding-top: $selectize-padding-dropdown-item-y + 2px;
    font-weight: bold;
    //font-size: 0.85em;
  }
  .optgroup {
    border-top: 1px solid $selectize-color-dropdown-border-top;
    &:first-child {
      border-top: 0 none;
    }
  }
}


// Styling..
.selectize-input {
  @include i-icon(arrow-down, after);

  background: none;
  border: 0;
  border-bottom: 1px solid #999;
  padding: 5px 0 5px;
  font-size: 16px;
  position: relative;
  z-index: 15;

  &:after {
    position: absolute;
    bottom: 9px;
    right: 0;

    transition: 0.3s ease transform;

    color: color(blue);

    font-size: 7px;
    line-height: 1;
  }

  &:before{
    display: none !important;
  }

  > input {
    line-height: 1;

    &::placeholder{
      color: #aaa9a9;
      font-size: 14px;
    }
  }

  &.dropdown-active {
    &:after {
      transform: rotate3d(0, 0, 1, 180deg);
    }
  }

  > .item {
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.2;
  }
}

.selectize-dropdown{
  transform: translate3d(0, 10px, 0);
  perspective: 1000px;
  transition-duration: .3s;
  transition-property: border, opacity, transform, visibility, height;
  transition-timing-function: ease;
  display: block !important;
  visibility: hidden !important;
  opacity: 0;

  &.-open{
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible !important;

    .active{
      background: none;
      color: color(blue);
    }
  }

  .optgroup-header {
    border-top: 0 none;
    font-size: 18px;
    line-height: 1.1;
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .option{
    line-height: 1;
    padding: 6px 10px;
    text-align: center;
    display: block;
    color: #888;
    border: 1px dashed #d9d9d9;
    margin-bottom: 9px;

    &:last-child{
      margin-bottom: 0;
    }
  }
}

.selectize-dropdown.plugin-optgroup_columns {
  .selectize-dropdown-content{
    @extend %clearfix;
  }

  .optgroup {
    padding: 0 15px 10px;
    border: 0;

    @include media(sm){
      float: left;
    }
  }
  .optgroup:before {
    display: none;
  }
  .optgroup-header {

  }

  .option{

  }
}

.selectize-dropdown.plugin-large_dropdown {
  padding-bottom: 15px;

  .selectize-dropdown-content{
    padding: 0 15px;
    margin-top: 15px;
  }
}


