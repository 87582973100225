@charset "UTF-8";

.l-sidebar{
  width: $sidebarWidthXs;
  background: #fbfbfb;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000;

  @include media(sm){
    width: $sidebarWidthSm;
  }

  @include media(md){
    width: $sidebarWidthMd;
  }

  @include media(lg){
    width: $sidebarWidthLg;
  }

  .menu{
    @extend %unstyled;

    font-size: 14px;

    > li {
      @include i-icon(arrow-3-right);

      margin-top: 12px;
      position: relative;

      &:first-child {
        margin-top: 0;
      }

      &:before {
        position: absolute;
        top: 6px;
        right: 100%;
        color: color(blue);
        font-size: 8px;
        display: none;
        margin-right: 18px;

        @include media(sm){
          font-size: 5px;
          margin-right: 6px;
          top: 8px;
        }

        @include media(md){
          font-size: 6px;
          margin-right: 10px;
          top: 7px;
        }

        @include media(lg){
          font-size: 8px;
          margin-right: 18px;
          top: 6px;
        }
      }

      &.active {
        &:before {
          display: block;
        }

        > a {
          color: #212121;
        }
      }
    }

    a {
      color: #999;
      line-height: 1.428571429;
      text-decoration: none;

      &:hover{
        color: #212121;
      }
    }
  }
}

.l-sidebar_inner{
  position: relative;
  padding: $sidebarPaddingXs;

  @include media(sm){
    width: $sidebarWidthSm;
    padding: $sidebarPaddingSm;
  }

  @include media(md){
    width: $sidebarWidthMd;
    padding: $sidebarPaddingMd;
  }

  @include media(lg){
    width: $sidebarWidthLg;
    padding: $sidebarPaddingLg;
  }

  &:after{
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    background: #fbfbfb;
    width: 1000px;
    display: none;

    @include media(sm){
      display: block;
    }
  }
}

.l-left-sidebar_inner{

  &:after{
    right: 100%;
  }
}

.l-right-sidebar_inner{

  &:after{
    left: 100%;
  }
}

.l-component{

  &.-with-left-sidebar,
  &.-with-right-sidebar {

    @include media(sm){
      float: left;
      width: 100%;
    }

    .l-component_content {

      @include media(sm){
        padding-right: $contentRightOffsetSm;
      }

      @include media(md){
        padding-right: $contentRightOffsetMd;
      }

      @include media(lg){
        padding-right: $contentRightOffsetLg;
      }
    }
  }

  &.-with-left-sidebar{
    .l-component_content {

      @include media(sm){
        margin-left: $sidebarWidthSm;
      }

      @include media(md){
        margin-left: $sidebarWidthMd;
      }

      @include media(lg){
        margin-left: $sidebarWidthLg;
      }
    }
  }

  &.-with-right-sidebar{
    .l-component_content {

      @include media(sm){
        margin-right: $sidebarWidthSm;
      }

      @include media(md){
        margin-right: $sidebarWidthMd;
      }

      @include media(lg){
        margin-right: $sidebarWidthLg;
      }
    }
  }
}

.l-left-sidebar{

  @include media(sm){
    float: left;
    margin-right: -$sidebarWidthSm;
  }

  @include media(md){
    margin-right: -$sidebarWidthMd;
  }

  @include media(lg){
    margin-right: -$sidebarWidthLg;
  }
}

.l-right-sidebar{

  @include media(sm){
    float: left;
    margin-left: -$sidebarWidthSm;
  }

  @include media(md){
    margin-left: -$sidebarWidthMd;
  }

  @include media(lg){
    margin-left: -$sidebarWidthLg;
  }
}


.-with-right-sidebar .m-carousel{

  @include media(sm){
    margin-right: -$contentRightOffsetSm;
  }

  @include media(md){
    margin-right: -$contentRightOffsetMd;
  }

  @include media(lg){
    margin-right: -$contentRightOffsetLg;
  }
}
