@charset "UTF-8";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Ubuntu', sans-serif;
}

p {
  margin: 0 0 10px 0;

  line-height: 1.5;
}

a {
  cursor: pointer;
  transition: .3s color ease;

  &:focus {
    outline: none;
  }
}


.e-gray{
  color: #666;
}

.e-blue{
  color: color(blue);
}


.page-header{
  color: #212121;
  line-height: 1.2;
  margin-bottom: 30px;

  >h1,>h2,>h3{
    margin: 0;
  }

  >h1{
    font-size: 33px;
  }
}

.l-component_content{
  font-size: 14px;

  img {
    max-width: 100%;
    height: auto;
  }

  p {
    line-height: 1.42;
    margin: 10px 0;

    &:first-child{
      margin-top: 0;
    }

    &:last-child{
      margin-bottom: 0;
    }
  }

  .e-gray{
    color: #666;
  }

  .e-blue{
    color: color(blue);
  }

  .e-pull-wrapper{
    overflow: hidden;
  }

  .e-pull-left{
    float: left;
    margin: 15px 15px 15px 0;
  }

  .e-pull-right{
    float: right;
    margin: 15px 0 15px 15px;
  }

  .e-lead{
    color: #212121;
    font-size: 24px;
    line-height: 1.416666667;
    margin: 20px 0;

    &:first-child{
      margin-top: 0;
    }

    &:last-child{
      margin-bottom: 0;
    }
  }

  .e-offset{
    margin: 28px 30px;

    &:first-child{
      margin-top: 0;
    }

    &:last-child{
      margin-bottom: 0;
    }
  }

  table {
    @extend .table;
    @extend .table-bordered;
    @extend .table-striped;

    background: #fff;
    margin: 25px 0;

    &:first-child{
      margin-top: 0;
    }

    &:last-child{
      margin-bottom: 0;
    }

    p {
      margin: 0;
    }
  }

  ul {
    margin: 25px 0;
    padding-left: 20px;

    > li {
      line-height: 1.42;
      margin-top: 13px;

      &:first-child{
        margin-top: 0;
      }
    }
  }

  a {
    color: color(blue);

    &:hover{
      text-decoration: underline;
    }

    &[href^='tel:']{
      text-decoration: none;

      &:hover{
        text-decoration: none;
      }
    }
  }

  .arrows-list{
    padding: 0;
    list-style-type: none;
    color: #212121;

    > li{
      @include i-icon(arrow-1-right);

      padding-left: 32px;
      position: relative;

      &:before{
        position: absolute;
        display: block;
        color: color(blue);
        left: 0;
        top: 4px;
        font-size: 8px;
      }
    }
  }

  .e-widget{
    background: #e4eef8;
    margin: 40px 0;
    padding: 23px 30px;

    &:first-child{
      margin-top: 0;
    }

    &:last-child{
      margin-bottom: 0;
    }
  }

  .e-lead-bold{
    font-weight: bold;
    font-size: 20px;
    line-height: 1.5;
    margin: 20px 0;

    &:first-child{
      margin-top: 0;
    }

    &:last-child{
      margin-bottom: 0;
    }
  }
}

.e-order-widget{
  width: 100%;
  margin: 40px 0;

  @include media(sm){
    display: table;
  }

  p{
    margin: 0;
  }
}

.e-order-widget_caption{
  font-weight: bold;
  padding: 20px 20px;
  font-size: 16px;
  line-height: 1.35;
  color: #212121;
  vertical-align: middle;
  border-width: 1px 1px 0 1px;
  border-color: color(blue);
  border-style: dashed;
  border-radius: 2px;

  @include media(sm){
    border-radius: 2px 0 0 2px;
    display: table-cell;
    padding: 20px 20px;
    font-size: 18px;
    border-width: 1px 0 1px 1px;
  }

  @include media(md){
    padding: 25px 30px;
    font-size: 20px;
  }
}

.e-order-widget_phone{
  background: color(blue);
  color: #fff;
  font-weight: bold;
  padding: 30px 20px;
  font-size: 22px;
  vertical-align: middle;
  text-align: center;
  border-radius: 2px;
  white-space: nowrap;

  @include media(sm){
    border-radius: 0 2px 2px 0;
    display: table-cell;
    padding: 30px 25px;
    font-size: 20px;
  }

  @include media(md){
    padding: 40px 35px;
    font-size: 24px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Loader
*/

.e-loader {
  font-size: 10px;
  text-indent: -9999em;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ffffff;
  background: linear-gradient(to right, color(blue) 10%, rgba(color(blue), 0) 42%);
  position: relative;
  animation: loader 1.4s infinite linear;
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: color(blue);
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: #f3f4f4;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Alert
*/

.alert{
  padding: 20px 20px 25px;
  background: #e4eef8;
  position: relative;
  opacity: 1;
  transform: scale(1);
  transition: .3s opacity ease, .3s transform ease;
  margin: 20px 0;

  @include media(sm){
    padding: 30px 30px 35px;
  }

  &.-out{
    transform: scale(0.8);
    opacity: 0;
  }

  > .close{
    @include i-icon(cross);

    position: absolute;
    font-size: 0;
    right: 0;
    top: 0;
    padding: 10px;
    color: #212121;
    opacity: 0.6;
    transition: .3s opacity ease;

    @include media(sm){
      padding: 20px;
    }

    &:hover{
      opacity: 1;
    }

    &:before{
      font-size: 12px;
    }
  }

  h1, h2, h3, h4, h5 {
    margin: 0 0 10px 0;
    line-height: 1.4;
  }
}

.alert-message{
  font-size: 14px;
  color: #212121;
  line-height: 1.3;

  p{
    margin: 0;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Hamburger
*/

.e-hamburger{
  cursor: pointer;
  width: 14px;
  padding: 16px 10px 16px 20px;
  box-sizing: content-box;

  @include media(sm){
    padding: 16px 15px;
    margin-left: -15px;
  }

  > span{
    display: block;
    width: 100%;
    background: color(blue);
    margin-top: 2px;
    height: 2px;

    &:first-child{
      margin-top: 0;
    }
  }
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Vertical tabs
*/

.m-vertical-tabs{
  width: 100%;
  display: table;
}

.m-vertical-tabs-nav{
  margin-bottom: 20px;

  @include media(md){
    margin: 0;
    display: table-cell;
    vertical-align: middle;
    width: 225px;
    padding-right: 30px;
  }

  &.-top{
    @include media(md){
      vertical-align: top;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-decoration: none;
    border: none;
    border-radius: 0;
    background: none;

    li {
      line-height: 1;
      margin: 5px 0 0 46px;

      @include media(lg){
        margin-left: 0;
      }

      &:first-child{
        margin-top: 0;
      }
    }
  }
}

.m-vertical-tabs-nav_tab{
  @include i-icon(arrow-3-right);

  position: relative;
  color: #999;

  &:before{
    position: absolute;
    right: 100%;
    margin-right: 18px;
    top: 11px;
    color: color(blue);
    font-size: 8px;
    line-height: 1;
    opacity: 0;
    transition: .3s opacity ease, .3s transform ease;
    transform: translate3d(-8px,0,0);
  }

  &.active{
    color: #212121;

    &:before{
      opacity: 1;
      transform: translate3d(0,0,0);
    }
  }

  > a {
    line-height: 1.1;
    text-decoration: none;
    color: inherit;
    padding: 8px 0;
    display: block;

    &:hover{
      text-decoration: none;

      color: #212121;
    }
  }
}

.m-vertical-tabs-content{
  @include media(md){
    display: table-cell;
    vertical-align: middle;
  }
}

a.-red-item{
  position: relative;
  &:after{
    content: "";
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    background: #f00;
    top: -4px;
    right: -16px;
    border-radius: 6px;
  }
}