@charset "UTF-8";

.l-contacts{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  ~ .l-content{
    flex: 0;
  }
}

.m-contacts{
  display: table;
  width: 100%;
}

.m-contacts_left,
.m-contacts_right{
  width: 50%;
  display: table-cell;
  vertical-align: middle;
}

.m-contacts_left{
  @include media(lg){
    padding-left: 68px;
  }
}

.m-contacts_header{
  @extend .page-header;

  margin-bottom: 110px;
}

.m-contacts_phone{
  margin-bottom: 15px;

  >span{
    font-weight: bold;
    font-size: 30px;
    line-height: 1;

    &.-blue{
      color: color(blue);
      margin-left: 5px;
    }
  }
}

.m-contacts_address{
  color: #212121;
  font-size: 14px;
  margin-bottom: 10px;

  p{
    margin: 0 0 5px 0;
    line-height: 1.7;
  }

  .-gray{
    color: #999;
  }
}

.m-contacts_email{
  >a{
    font-size: 14px;
    color: #212121;
    text-decoration: none;

    &:hover{
      text-decoration: underline;
      color: color(blue);
    }
  }
}

.m-contacts_buttons{
  margin-top: 112px;
  >a{
    font-size: 14px;
  }
}

.m-contacts_right{
  position: relative;
  height: 535px;
  overflow: hidden;

  &:after{
    display: block;
    position: absolute;
    content: ' ';
    left: 0;
    bottom: 0;
    top: 0;
    width: 6px;
    background-image: linear-gradient(90deg, rgba(0,0,0, 0.03) 0%, rgba(255,255,255, 0) 100%);
  }
}

.m-contacts_map_wrapper{
  transition: 0.35s opacity ease, 0.35s transform ease, 0.35s visibility ease;
  transform: translate3d(0,0,0);
  visibility: visible;
  opacity: 1;
  overflow: hidden;

  &.-hide{
    transform: translate3d(-100%,0,0);
    visibility: hidden;
    opacity: 0;
  }
}

.m-contacts_map{
  max-width: 100%;
  width: auto;
  height: auto;
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Form
*/

.m-contacts_form_wrapper{
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  background: #fff;
  border-top: 4px solid #f5f5f5;
  border-bottom: 4px solid #f5f5f5;
  border-right: 4px solid #f5f5f5;
  padding: 100px 60px 100px 80px;
  transition: 0.35s opacity ease, 0.35s transform ease, 0.35s visibility ease;
  transform: translate3d(-100%,0,0);
  visibility: hidden;
  opacity: 0;

  &.-show{
    transform: translate3d(0,0,0);
    visibility: visible;
    opacity: 1;
  }
}


.m-contacts_form_close{
  position: absolute;
  line-height: 1;
  color: #d1d1d1;
  top: 35px;
  right: 45px;
  font-size: 16px;
  padding: 20px;
  cursor: pointer;
  transition: .3s color ease;

  &:hover{
    color: #212121;
  }
}
