@charset "UTF-8";

.m-blog{

}

.m-blog_intro{
  margin-bottom: 40px;
  margin-top: 40px;

  @include media(sm){
    margin-bottom: 80px;
  }
}

.m-blog_category_desc{
  padding: 20px 20px;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  color: #fff;
  display: flex;
  flex: 1;
  flex-flow: column wrap;
  justify-content: center;
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.466666667;

  @include media(sm){
    padding: 80px 67px;
    min-height: 360px;
  }

  @include media(lg){
    min-height: 415px;
  }

  p{
    font-size: 14px;
    font-weight: 300;
    line-height: 1.466666667;
    margin: 5px 0;

    @include media(sm){
      font-size: 15px;
    }
  }

  .large-text{
    font-weight: bold;
    margin: 5px 0 10px 0;
    line-height: 1;
    font-size: 30px;

    @include media(sm){
      font-size: 42px;
    }

    @include media(md){
      font-size: 42px;
    }

    @include media(lg){
      font-size: 50px;
    }
  }
}

.m-blog_list{
  margin-bottom: 40px;
}


.m-blog_group{
  margin-top: 40px;

  &:first-child{
    margin-top: 0;
  }
}

.m-blog_item{
  @include clearfix();

  float: none;

  margin-top: 40px;

  &:first-child{
    margin-top: 0;
  }

  .article-header{
    margin-bottom: 12px;

    @include media(sm){
      margin-top: -7px;
    }

    > h1, >h2, >h3, >h4{
      margin: 0;
      font-size: 22px;
      line-height: 1.2;

      @include media(sm){
        font-size: 26px;
      }
    }
    a{
      color: #212121;
      text-decoration: none;

      &:hover{
        color: color(blue);
      }
    }
  }
  .item-image{
    float: left;
    width: 180px;

    a {
      display: block;
      font-size: 0;
    }
    img{
      max-width: 100%;
    }
  }
}

.m-blog_item_pubdate{
  float: left;
  padding-right: 10px;
  width: 55px;
  font-size: 10px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 1;

  @include media(sm){
    margin-top: -5px;
    padding-right: 15px;
    width: 85px;
    font-size: 13px;
  }

  > time{
    display: block;
    color: color(blue);
    font-weight: bold;

    @include media(sm){
      width: 50px;
      text-align: center;
    }
  }
}

.m-blog_item_pubdate_day{
  display: inline-block;
  width: 100%;
  font-size: 3.07692em;
}

.m-blog_item_pubdate_month{
  display: inline-block;
  text-transform: lowercase;
  font-size: 1.38461em;
}

.m-blog_item_hits{
  @include i-icon(eye);

  position: relative;
  margin-top: 27px;
  padding-left: 24px;
  color: #bebcbc;
  font-size: 13px;

  &:before{
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -5px;
    font-size: 10px;
  }
}

.m-blog_item_desc{
  overflow: hidden;

  @include media(sm){
    padding-left: 30px;
  }
}

.m-blog_item_introtext{
  p {
    color: #666;
    font-size: 13px;
    line-height: 1.571428571;

    @include media(sm){
      font-size: 14px;
    }
  }
}

.m-blog_item_tags,
.m-tags,
.item-page {
  margin-top: 5px;

  > .tags{
    @extend %unstyled;

    @include clearfix();

    > li {
      line-height: 1;
    }
    &.inline{
      > li {
        float: left;
        margin: 9px 9px 0 0;
      }
    }
    a {
      display: inline-block;
      padding: 5px 12px;
      border: 1px solid #eceeef;
      color: #b2b1b1;
      text-decoration: none;
      white-space: nowrap;
      font-size: 13px;
      line-height: 1;
      transition-property: border, color;

      &:hover{
        border-color: color(blue);
        color: color(blue);
      }
    }
  }
}

.items-more{
  ol{
    line-height: 1.5em;
  }
}