@charset "UTF-8";

html {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

body {
  min-width: 320px;
  //height: 100%;
  min-height: 100vh;

  background-color: #fff;

  font-family: arial, verdana, sans-serif;
  font-size: 100%;
  line-height: 1;

  -webkit-overflow-scrolling: touch;
  overflow: auto;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: scrollbar;

  &.frontpage, &.-with-substrate{
    .l-page{
      @include media(sm){
        background-image: linear-gradient(90deg, #fff 0%, #fff 50%, #f3f3f3 50%, #f7f7f7 50.2%, #fbfbfb 50.5%);
      }
    }
  }

  &.-menu-open{
    overflow: hidden;

    @include media(md){
      overflow: auto;
    }
  }
}

.l-page{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.l-content{
  flex: 1 0 auto;
  overflow: hidden;
  position: relative;
}

.l-component_content {

  @include media(sm){
    padding-left: $contentLeftOffsetSm;
  }

  @include media(md){
    padding-left: $contentLeftOffsetMd;
  }

  @include media(lg){
    padding-left: $contentLeftOffsetLg;
  }
}

.tab-pane {
  position: absolute;
  left: -99999px;

  opacity: 0;
  visibility: hidden;
  transition: .3s opacity ease, .3s transform ease, .3s visibility ease;

  &.active {
    position: static;

    display: block;
    visibility: visible;
    transform: translate3d(0,0,0);

    opacity: 1;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Backdrop
*/


.e-menu-backdrop {
  position: fixed;
  z-index: $mobile-menu-backdrop-zindex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  visibility: hidden;

  transition: opacity 0.3s ease, visibility 0.3s ease;

  opacity: 0;
  background-color: #4c4c4c;

  &.-in {
    visibility: visible;

    opacity: 0.6;
  }

  &.-level-2 {
    z-index: $submenu-backdrop-zindex;
  }
}
