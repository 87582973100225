
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Base form
*/

.m-form{
  &.-in-modal{
    padding: 0;
    width: 100%;
    font-size: 15px;

    @include media(sm){
      font-size: 18px;
    }
  }

  form{
    padding: 0;
    margin: 0;
    font-size: 14px;
  }

  .parsley-errors-list{
    @extend %unstyled;

    position: absolute;
    top: 100%;
    font-size: 0.85em;
    color: #f24841;
    margin-top: 5px;

    > li{
      margin-top: 5px;
      line-height: 1.1;

      &:first-child{
        margin-top: 0;
      }
    }
  }
}

.m-form_heading{
  @extend %clearfix;

  margin-bottom: 20px;
  position: relative;

  @include media(sm){
    margin-bottom: 35px;
  }

  &_title{
    line-height: 1.4;
    margin: 0;
    font-weight: bold;
    font-size: 20px;

    @include media(sm){
      font-size: 24px;
    }

    &.-with-help{
      @include media(sm){
        float: left;
      }
    }
  }

  &_caption{
    color: #666666;
    margin-top: 10px;
    font-size: 0.833em;
    line-height: 1.466666667;

    p{
      margin: 0;
    }
  }

  .m-form_help_url{
    @include media(sm){
      text-align: right;
      overflow: hidden;
    }
  }
}


.m-form_help_url{
  line-height: 32px;

  > a {
    line-height: 1.2;
    display: inline-block;
  }
}

.m-form_box{
  margin-bottom: 20px;
  position: relative;

  &.-md-offset{
    @include media(sm){
      margin-bottom: 30px;
    }
  }
}

.m-form_input,
.m-form_textarea{
  max-width: 100%;
  width: 100%;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #e0e0e0;
  padding: 5px 0 5px;
  line-height: 1.2;
  color: #212121;
  transition: .3s border ease;
  font-size: 17px;
  border-radius: 0;
  box-shadow: none;

  &:focus{
    outline: none;
    border-color: color(blue);
  }

  &::placeholder{
    color: #d0d0d0;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.parsley-success{
    border-color: color(blue);
  }

  &.parsley-error{
    border-color: #f24841;
  }
}

.m-form_textarea{
  resize: none;
  height: 31px;
}

.m-form_checkbox{

  >input[type="checkbox"],
  >input[type="radio"]{
    position: absolute;

    visibility: hidden;

    opacity: 0;
  }
}

.m-form_checkbox_label{
  @include i-icon(daw);

  color: #d0d0d0;
  padding-left: 25px;
  position: relative;
  cursor: pointer;
  transition: .3s color ease;
  line-height: 1.285714286;

  &:before,
  &:after{
    position: absolute;
  }

  &:before{
    color: color(blue);
    font-size: 5px;
    top: 6px;
    left: 4px;
    opacity: 0;
    transform: translate3d(0, -5px, 0);
    transition: .3s opacity ease, .3s transform ease;
  }

  &:after{
    content: ' ';
    left: 0;
    top: 1px;
    width: 15px;
    height: 15px;
    border-radius: 1px;
    border: 1px solid #d1d1d1;
    transition: .3s border ease;
  }

  [type="checkbox"]:checked + & {

    color: #212121;

    &:after {
      border-color: color(blue);
    }

    &:before {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}


.m-form_attachment_box{
  position: relative;
  overflow: hidden;

  &:hover{
    .m-form_attachment_btn{
      border-color: transparent;
    }
  }
}

.m-form_attachment_input{
  position: absolute;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
}

.m-form_attachment_btn{
  display: inline-block;
  color: color(blue);
  border-bottom: 2px dotted;
  font-weight: 300;
  line-height: 1.3;
  transition: .3s border ease;

  &:hover{
    border-color: transparent;
  }
}

.m-form_attachment_filename{
  overflow: hidden;
  padding-right: 20px;
  position: relative;
  opacity: 0;
  visibility: hidden;
  transition: .3s opacity ease, .3s margin ease, .3s visibility ease;
  height: 0;

  &.-show{
    opacity: 1;
    visibility: visible;
    height: auto;
    margin: 10px 0;
  }

  >span{
    font-size: 13px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1;
    display: inline-block;
  }

  >i{
    position: absolute;
    right: 0;
    top: 0;
    line-height: 1;
    font-size: 10px;
    cursor: pointer;
    transition: 0.3s opacity ease;
    opacity: 0.5;

    &:hover{
      opacity: 1;
    }
  }
}

.m-form_submit{
  position: relative;
  margin-bottom: 0;

  &.-with-margin{
    margin-bottom: 20px;
  }

  .e-btn{
    min-width: 1px;
    width: 100%;
  }

  > .e-loader{
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -20px;
    margin-left: -20px;
    opacity: 0;
    visibility: hidden;
    transition: .3s opacity ease, .3s visibility ease;

    &:after{
      background: #fff;
    }
  }
}

.m-form_footer_caption{
  line-height: 1.285714286;
}

.m-form_submit_btn{
  transition: .3s opacity ease, .3s visibility ease;
  font-size: 14px;
}

.-sending{
  .e-loader{
    opacity: 1;
    visibility: visible;
  }

  .m-form_submit_btn {
    opacity: 0;
    visibility: hidden;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Gray
*/

.m-form-gray{
  background: #fafafb;
  padding: 25px 35px;

  @include media(sm){
    padding: 55px 65px;
  }

  @include media(md){
    padding: 55px 65px;
  }

  @include media(lg){
    padding: 55px 65px;
  }
}

.m-form-gray_help_url{
  a {
    text-decoration: none;
    color: #b8b8b8;

    &:hover{
      text-decoration: none;
      color: color(blue);
    }
  }
}

.m-form-gray_input,
.m-form-gray_textarea{
  background: none;
  border-color: #999;

  &::placeholder{
    color: #aaa9a9;
  }
}

.m-form-gray_footer_caption{
  color: #d0d0d0;
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Contacts
*/

.m-form-contacts{
  @extend %clearfix;
}

.m-form-contacts_box{
  float: left;
  width: 100%;
}

.m-form_phone_box,
.m-form_email_box {
  &.m-form-contacts_box{
    @include media(sm){
      width: 50%;
    }
  }
}

.m-form_email_box{
  &.m-form-contacts_box{
    @include media(sm){
      padding-left: 30px;
    }
  }
}
