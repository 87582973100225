@charset "UTF-8";

.m-carousel{
  margin: 30px 0;

  &:first-child{
    margin-top: 0;
  }

  &:last-child{
    margin-bottom: 0;
  }
}

.m-carousel_slide{
  > a {
    display: block;
  }

  img {
    display: block;
    margin: auto;
    max-width: 100%;
  }
}

.m-carousel_controls{
  @extend %clearfix;

  position: relative;
}

.m-carousel_controls_arrows{
  @extend %clearfix;

  @include media(sm){
    float: right;
    margin-right: 40px;
  }
}

.m-carousel_controls_dots{
  @extend %clearfix;

  display: none;

  @include media(sm){
    float: left;
    display: block;
  }



  .slick-dots{
    @extend %unstyled;
    @extend %clearfix;

    @include media(sm){
      margin-left: 21px;
      margin-top: 12px;
    }

    > li {
      float: left;
      line-height: 1;
      margin: 0;

      &.slick-active{
        button{
          &:after{
            background: #c3c6c8;
          }
        }
      }
    }

    button{
      display: block;
      background: none;
      padding: 9px;
      margin: 0;
      font-size: 0;
      border: 0;

      &:focus{
        outline: none;
      }

      &:after{
        content: ' ';
        display: block;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        border: 1px solid #c3c6c8;
        transition: .3s background ease;
      }
    }
  }
}

.slick-arrow {
  background: none;
  border: 0;
  margin: 0;
  font-size: 0;
  color: #c3c6c8;
  padding: 15px;
  float: left;
  transition: .3s color ease;

  @include media(sm){
    padding: 20px;
  }

  &:hover{
    color: #212121;
  }

  &:focus{
    outline: none;
  }

  &:before{
    font-size: 12px;
  }
}

.slick-prev {
  @include i-icon(arrow-2-left);
}

.slick-next{
  @include i-icon(arrow-2-right);
}

