/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Common, default styles for the notification box
*/

@mixin ff-base(){
  font-family: 'Open Sans', sans-serif;
}

@mixin ff-heading(){
  font-family: 'PT Serif', serif;
}

.ns-box {
  @include ff-heading;

  position: fixed;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 65000;
  padding: 22px;

  background: rgba(42, 45, 50, 0.85);
  color: rgba(250, 251, 255, 0.95);

  font-size: 100%;
  line-height: 1.4;

  pointer-events: none;
}

.ns-box.ns-show {
  pointer-events: auto;
}

.ns-box a {
  color: inherit;

  font-weight: 700;
  opacity: 0.7;
}

.ns-box-inner {
  @include clearfix;
}

.ns-box a:hover,
.ns-box a:focus {
  opacity: 1;
}

.ns-box p {
  margin: 0;
}

.ns-box.ns-show,
.ns-box.ns-visible {
  pointer-events: auto;
}

.ns-close {
  @include i-icon(cross);

  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  display: inline-block;

  //overflow: hidden;

  //width: 20px;
  //height: 20px;

  //text-indent: 100%;
  cursor: pointer;
  line-height: 1;
  z-index: 65001;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  &:hover,
  &:focus {
    outline: none;
  }

  &::before {
    font-size: 12px;
  }
}

.ns-title {
  font-size: 135%;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: -6px;
}

.ns-content-wrap {
  margin-left: 60px;
  margin-top: -3px;
}

.ns-image {
  float: left;
  width: 45px;
}

.ns-icon {
  float: left;
  width: 50px;

  &::before {
    color: inherit;
    font-size: 50px;
    line-height: 1;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Attached
*/

/* Growl-style notifications */
.ns-attached {
  right: 0;
  top: 0;

  min-width: 220px;
  max-width: 100%;

  @include media(sm){
    max-width: 400px;
    right: 10%;
  }

  @include media(xlg){
    right: 100px;
  }

}

[class^="ns-effect-"].ns-attached.ns-hide,
[class*=" ns-effect-"].ns-attached.ns-hide {
  animation-direction: reverse;
}

/* Individual effects */

/* Flip */
.ns-effect-flip {
  bottom: 0;
  padding: 1.5em;
  max-width: 300px;
  background: #9bd1a2;
  font-size: 1.15em;
  transform-origin: 50% 100%;
  backface-visibility: hidden;
}

.ns-effect-flip .ns-close::before,
.ns-effect-flip .ns-close::after {
  background: #5a9862;
}

.ns-effect-flip .ns-close:hover::before,
.ns-effect-flip .ns-close:hover::after {
  background: #4e8455;
}

.ns-effect-flip.ns-show,
.ns-effect-flip.ns-hide {
  animation-name: animFlipFront;
  animation-duration: 0.3s;
}

.ns-effect-flip.ns-hide {
  animation-name: animFlipBack;
}

@keyframes animFlipFront {
  0% {
    transform: perspective(1000px) rotate3d(1, 0, 0, -90deg);
  }

  100% {
    transform: perspective(1000px);
  }
}

@keyframes animFlipBack {
  0% {
    transform: perspective(1000px) rotate3d(1, 0, 0, 90deg);
  }

  100% {
    transform: perspective(1000px);
  }
}

/* Bouncy Flip adapted from animate.css by Dan Eden: http://daneden.github.io/animate.css/ */
.ns-effect-bouncyflip {
  top: 0;

  background: #fff;
  box-shadow: 0 13px 10px -5px rgba(0, 0, 0, 0.2);
  color: #484860;

  transform-origin: 50% 0%;
}

.ns-effect-bouncyflip .icon {
  display: block;

  position: absolute;
  top: 2em;
  right: 2em;
}

.ns-effect-bouncyflip p {
  margin: 0 0 3px 0;
}

.ns-effect-bouncyflip .ns-close::after,
.ns-effect-bouncyflip .ns-close::before {
  //background: #484860;
}

.ns-effect-bouncyflip .ns-close:hover::after,
.ns-effect-bouncyflip .ns-close:hover::before {
  //background: #545484;
}

.ns-effect-bouncyflip.ns-show,
.ns-effect-bouncyflip.ns-hide {
  animation-name: flipInX;
  animation-duration: 0.8s;
}

@keyframes flipInX {
  0% {
    transition-timing-function: ease-in;
    transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
  }

  40% {
    transition-timing-function: ease-out;
    transform: perspective(400px) rotate3d(1, 0, 0, 20deg);
  }

  60% {
    opacity: 1;

    transition-timing-function: ease-in;
    transform: perspective(400px) rotate3d(1, 0, 0, -10deg);
  }

  80% {
    transition-timing-function: ease-out;
    transform: perspective(400px) rotate3d(1, 0, 0, 5deg);
  }

  100% {
    transform: perspective(400px);
  }
}

.ns-effect-bouncyflip.ns-hide {
  animation-name: flipInXSimple;
  animation-duration: 0.3s;
}

@keyframes flipInXSimple {
  0% {
    transition-timing-function: ease-in;
    transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
  }

  100% {
    transform: perspective(400px);
  }
}

