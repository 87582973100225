@charset "UTF-8";

// Strips the unit from a number
@function strip-units($value) {
  @return $value / ($value * 0 + 1);
}

// Horizontally and vertically centers block element
@mixin center-abs {
  position: absolute;
  top: 50%;
  left: 50%;
  transition: translate(-50%, -50%);
}

// Retina image media query helper
@mixin image-2x($image, $width, $height) {
  @media (-webkit-min-device-pixel-ratio: 1.3), (min-resolution: 124dpi), (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}

// Convert pixels to ems
@function em($pxval, $base: $em-base) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return ($pxval / $base) * 1em;
}

// Convert pixels to rems
@function rem($pxval) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }

  $base: $em-base;
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return ($pxval / $base) * 1rem;
}

// Background gradient helper
@mixin linear-gradient($pos, $g1, $g2: null,
                       $g3: null, $g4: null,
                       $g5: null, $g6: null,
                       $g7: null, $g8: null,
                       $g9: null, $g10: null,
                       $fallback: null) {
  // Detect what type of value exists in $pos
  $pos-type: type-of(nth($pos, 1));
  $pos-spec: null;
  $pos-degree: null;

  // If $pos is missing from mixin, reassign vars and add default position
  @if ($pos-type == color) or (nth($pos, 1) == 'transparent') {
    $g10: $g9;
    $g9: $g8;
    $g8: $g7;
    $g7: $g6;
    $g6: $g5;
    $g5: $g4;
    $g4: $g3;
    $g3: $g2;
    $g2: $g1;
    $g1: $pos;
    $pos: null;
  }

  @if $pos {
    $positions: _linear-positions-parser($pos);
    $pos-degree: nth($positions, 1);
    $pos-spec: nth($positions, 2);
  }

  $full: $g1, $g2, $g3, $g4, $g5, $g6, $g7, $g8, $g9, $g10;

  // Set $g1 as the default fallback color
  $fallback-color: nth($g1, 1);

  // If $fallback is a color use that color as the fallback color
  @if (type-of($fallback) == color) or ($fallback == 'transparent') {
    $fallback-color: $fallback;
  }

  background-color: $fallback-color;
  background-image: -webkit-linear-gradient($pos-degree $full); // Safari 5.1+, Chrome
  background-image: unquote('linear-gradient(#{$pos-spec}#{$full})');
}


/// Usage:
/// Use vars from $color-stack.
/// a {
///   color: color(base);
///   background: color(base, hover);
///   border-color: color(orange, hover, 0.4);
/// }

@function color($group, $shade: normal, $transparency:1) {
  @each $color in $color-stack {
    $c-group: map-get($color, group);
    $c-shade: map-get($color, id);
    @if($group == map-get($color, group) and $shade == map-get($color, id)) {
      @return rgba(map-get($color, color), $transparency);
    }
  }
}

/// Usage:
/// Use vars from $media-stack or custom.
/// h1{
///   color: #333;
///   @include media(tablet){
///     font-size: 1rem;
///   };
///   @include media(small, inbetween){
///     font-size: 1.2rem;
///   };
///   @include media(small){
///     color: #000;
///   };
///   @include media(custom, screen, " (max-device-width: 360px)"){
///     color: blue;
///   };
/// }

@mixin media($group, $id: general, $customRule: '') {
  @each $media in $media-stack {
    @if($group == map-get($media, group) and $id == map-get($media, id)) {
      $rule: map-get($media, rule);
      @media #{$rule} #{$customRule} {
        @content
      }
    }
  }
}

// HiDPI helper
@mixin hidpi($ratio: 1.3) {
  @media only screen and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min--moz-device-pixel-ratio: $ratio),
  only screen and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-resolution: round($ratio * 96dpi)),
  only screen and (min-resolution: $ratio * 1dppx) {
    @content;
  }
}
