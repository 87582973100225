@charset "UTF-8";

.l-header {
  position: relative;
  flex-shrink: 0;
}

.l-header-bar {
  position: relative;
  z-index: $header-xs-zindex;
  background: transparent;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: 0.3s background ease;

  @include media(sm) {
    padding-top: 45px;
  }
  @include media(md) {
    z-index: $header-md-zindex;
  }

  &.-with-bg,
  &.-light {
    background: #fff;
  }
}

.l-header-logo {
  margin-bottom: 20px;
  margin-left: -3px;
  font-size: 0;

  @include media(sm) {
    margin-bottom: 0;
    text-align: left;
  }

  a {
    display: inline-block;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Main menu
*/

.l-header-bar-nav{
  > .menu {
    @extend %unstyled;

    display: table;
    margin-bottom: 25px;
    width: 100%;

    @include media(sm) {
      margin-top: 7px;
      margin-bottom: 0;
    }
    @include media(md) {
      margin-top: 11px;
    }
    @include media(lg) {
      margin-top: 12px;
    }

    > li {
      display: table-cell;
      vertical-align: middle;
      text-align: center;

      @include media(sm) {
        text-align: left;
      }

      &.-in > a,
      &.active > a {
        &:before {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }

    a {
      @include i-icon(arrow-down-mini);

      position: relative;
      color: #212121;
      text-decoration: none;
      font-size: 13px;

      @include media(sm) {
        font-size: 14px;
      }

      &:before {
        position: absolute;
        bottom: -15px;
        left: 50%;
        display: block;
        margin-left: -5px;
        color: color(blue);
        font-size: 7px;
        opacity: 0;
        transition: 0.3s ease;
        transform: translate3d(0, -7px, 0);

        @include media(sm) {
          bottom: -25px;
        }
      }
    }
  }
}

.l-header-menu-toggler {
  float: right;
  margin-right: -10px;

  @include media(sm){
    float: none;
    margin-right: 0;
  }
}

.l-header-phone-wrap {
  @include clearfix();

  margin: 0 -15px;
  padding: 10px 15px;
  background: color(blue);
  color: #fff;

  @include media(sm){
    margin: 0;
    padding: 0;
    background: none;
    color: #212121;
  }
}

.l-header-phone {
  float: left;

  @include media(sm){
    float: none;
    margin-top: 16px;
    text-align: right;
  }
  @include media(md) {
    margin-top: 10px;
  }
  @include media(lg) {
    margin-top: 10px;
  }

  > a {
    @include i-icon(phone);

    position: relative;
    display: inline-block;
    padding-left: 35px;
    color: inherit;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    font-family: 'Ubuntu', sans-serif;
    line-height: 28px;

    @include media(sm){
      padding-left: 0;
      line-height: inherit;

      &::before {
        display: none;
      }
    }
    @include media(md) {
      font-size: 16px;
    }
    @include media(lg) {
      font-size: 18px;
    }

    &:hover{
      color: inherit;

      @include media(sm){
        color: color(blue);
      }
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 1px;
      display: block;
      margin-top: -10px;
      color: #fff;
      font-size: 20px;
      line-height: 1;
    }
  }
}

.l-header-location {
  float: right;

  a {
    @include i-icon(location);

    display: inline-block;
    padding: 0 0 5px 15px;
    border-left: 1px solid #0e589d;
    color: #fff;
    text-decoration: none;
    font-size: 0;
    line-height: 1;

    &::before {
      font-size: 25px;
    }

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Submenu
*/

.l-header-submenu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: $submenu-zindex;
  visibility: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px 0;
  max-width: 95%;
  width: auto;
  background: #f8f8f8;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
  transform: translate3d(85%, 0, 0);

  @include media(sm){
    max-width: 100%;
  }
  @include media(md) {
    position: absolute;
    top: 100%;
    right: 0;
    bottom: initial;
    left: 0;
    overflow: hidden;
    padding: 60px 0 80px 0;
    width: 100%;
    transform: translate3d(0, -20px, 0);
  }

  &.-open {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .e-btn {
    display: block;
    margin-top: 10px;

    &:first-child{
      margin-top: 0;
    }
  }
}

.l-header-submenu-close {
  @include i-icon(cross);

  padding: 20px 0;
  color: #aaa;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  transition: 0.3s color ease;

  @include media(sm) {

  }
  @include media(md) {
    margin-top: -40px;
  }

  &:hover {
    color: #333;
  }

  &:before{
    color: inherit;
  }
}

.l-header-submenu-buttons {
  margin-bottom: 25px;

  @include media(sm) {
    margin-bottom: 0;
  }
}

.l-header-submenu-text {
  margin-top: 20px;

  &:first-child{
    margin-top: 0;
  }

  p {
    margin: 0;
    color: #888;
    font-size: 12px;
    line-height: 1.6;
  }
}

.l-header-submenu-menu {
  @extend %unstyled;
  @extend %clearfix;

  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0;
  -webkit-transition: 0.5s opacity ease;
  transition: 0.5s opacity ease;

  @include media(md){
    margin-top: -9px;
  }

  &.-open{
    display: block;
    width: auto;
    height: auto;
    opacity: 1;
  }

  > li {
    color: #212121;

    @include media(md){
      display: inline-block;
      padding: 0 15px 0 0;
      width: 50%;
      vertical-align: top;

      &:nth-child(2n) {
        padding: 0 0 0 15px;
      }

      &:first-child + li {
        > a {
          border-top: 0;
        }
      }
    }

    &.active,
    &.current {
      color: color(blue);
    }

    &:first-child {
      > a {
        border-top: 0;
      }
    }

    > a {
      display: block;
      padding: 12px 0;
      border-top: 1px solid #e8e8e8;
      color: inherit;
      text-decoration: none;
      font-size: 14px;
      line-height: 1.38;

      @include media(sm){
        padding: 12px 0;
        font-size: 16px;
      }
      @include media(md){
        padding: 8px 0;
        font-size: 13px;
      }

      &:hover {
        color: color(blue);
      }
    }
  }
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Mobile menu
*/

.l-mobile-nav{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: $mobile-menu-zindex;
  visibility: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 25px 30px 25px 25px;
  max-width: 100%;
  width: auto;
  background: #fff;
  opacity: 0;
  transition: 0.35s opacity ease, 0.35s transform ease, 0.35s visibility ease;
  transform: translate3d(-85%, 0, 0);
  transform-origin: 50% 50%;
  perspective: 1000px;

  @include media(sm){
    padding: 25px 100px 25px 25px;
  }

  &.-open {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  > .menu{
    @extend %unstyled;

    overflow-x: hidden;
    overflow-y: auto;

    > li{
      @include i-icon(arrow-down-mini);

      position: relative;
      margin-top: 5px;
      padding-left: 23px;
      color: #212121;
      font-size: 16px;

      @include media(sm){
        font-size: 16px;
      }

      &:first-child{
        margin-top: 0;
      }

      &:before{
        position: absolute;
        top: 14px;
        left: 0;
        color: color(blue);
        font-size: 10px;
        line-height: 1;
        opacity: 0;
        transform: rotate(-90deg);
      }

      &.active {
        &:before{
          opacity: 1;
        }
      }
    }

    a {
      display: block;
      padding: 10px 0;
      color: inherit;
      text-decoration: none;
      line-height: 1.2;

      &:hover {
        color: color(blue);
        text-decoration: none;
      }
    }
  }
}

.l-mobile-nav-logo{
  padding: 0 40px 55px 0;
  max-width: 100%;

  @include media(sm){
    padding: 0 0 55px 0;
  }

  a{
    display: block;
    font-size: 0;
  }

  img{
    max-width: 100%;
    height: auto;
  }
}

.l-mobile-nav-close{
  position: absolute;
  top: 25px;
  right: 0;
  padding: 20px;
  color: #aaa;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  transition: 0.3s color ease;

  @include media(sm) {
    right: 10px;
  }

  &:hover {
    color: #333;
  }

  &:before{
    color: inherit;
  }
}
