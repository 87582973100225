@charset "UTF-8";

.l-breadcrumbs{
  margin-bottom: 27px;
  margin-top: 8px;
  display: none;

  @include media(sm){
    display: block;
  }
}

.breadcrumb {
  @extend %unstyled;
  @extend %clearfix;

  @include media(sm){
    margin-left: $contentLeftOffsetSm;
  }

  @include media(md){
    margin-left: $contentLeftOffsetMd;
  }

  @include media(lg){
    margin-left: $contentLeftOffsetLg;
  }

  > li {
    float: left;
    line-height: 1;
    display: block;

    &:last-child{
      position: relative;
      background: #fff;

      &:after, &:before{
        position: absolute;
        top: 0;
        left: 100%;
        content: ' ';
        display: block;
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
      }

      &:before{
        border-left: 13px solid #eef0f1;
      }

      &:after{
        border-left: 13px solid #fff;
        margin-left: -1px;
      }
    }

    > a,
    > span{
      font-size: 12px;
      padding: 8px 15px;
      line-height: 1;
      border-style: solid;
      border-color: #eef0f1;
      border-width: 1px 0 1px 1px;
      white-space: nowrap;
      display: inline-block;
    }

    > a {
      color: #b2b1b1;
      text-decoration: none;

      &:hover {
        color: #888;
        text-decoration: none;
      }
    }

    > span {
      color: #888;
    }

    > .divider {
      display: none;
    }
  }
}
