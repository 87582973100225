@charset "UTF-8";

.l-footer {
  flex-shrink: 0;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #fafafb;
  box-shadow: inset 0 15px 15px -15px rgba(0, 0, 0, 0.03);

  @include media(sm){
    margin-top: 0;
    background: none;
    box-shadow: none;
  }

  @include media(md){
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.l-footer-menu {
  @extend %unstyled;

  margin-bottom: 15px;
  width: 100%;
  font-size: 0;

  @include media(sm) {
    margin-bottom: 0;
    text-align: left;
  }

  > li {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }

    &.active {
      > a {
        color: color(blue);
        text-decoration: none;
      }
    }

    > a {
      display: block;
      color: #212121;
      text-decoration: none;
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.l-footer-left,
.l-footer-center {
  padding-top: 10px;
}

.l-footer-right {
  @include media(sm) {
    text-align: right;
  }

  .e-btn {
    margin-bottom: 5px;
    margin-left: 5px;

    @include media(md) {
      margin-bottom: 0;
      margin-left: 20px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  p{
    margin: 0;
    line-height: 1;
  }
}

.l-footer-developer {
  @include i-icon(74);

  display: inline-block;
  float: right;
  color: #c4c4c4;
  text-decoration: none;
  font-size: 0;
  line-height: 1;

  &::before {
    color: inherit;
    font-size: 32px;
  }

  &:hover, &:focus {
    text-decoration: none;
  }

  &:hover {
    color: #EF3E47;
  }
}
