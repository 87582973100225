@charset "UTF-8";

@keyframes out-vertical-animation {
  from {
    transform: translate3d(0,0,0);

    opacity: 1;
  }
  to {
    transform: translate3d(0,-200%,0);

    opacity: 0;
  }
}

@keyframes in-vertical-animation {
  from {
    transform: translate3d(0,200%,0);

    opacity: 0;
  }
  to {
    transform: translate3d(0,0,0);

    opacity: 1;
  }
}

@keyframes out-horizontal-animation {
  from {
    transform: translate3d(0,0,0);

    opacity: 1;
  }
  to {
    transform: translate3d(-100%,0,0);

    opacity: 0;
  }
}

@keyframes in-horizontal-animation {
  //from {
  //  transform: translate3d(100%,0,0);
  //
  //  opacity: 0;
  //}
  //to {
  //  transform: translate3d(0,0,0);
  //
  //  opacity: 1;
  //}
  from {
    transform: translate3d(-100%,0,0);

    opacity: 0;
  }
  to {
    transform: translate3d(0,0,0);

    opacity: 1;
  }
}
