@charset "UTF-8";

$em-base: 16px !default;

$color-stack:
    (group: blue, id: normal, color: #0d69be),
    (group: blue, id: hover, color: #2175c3);

$media-stack:
  (group: lg, id: general, rule: "only screen and (min-width: 1200px)"),
  (group: md, id: general, rule: "only screen and (min-width: 992px)"),
  (group: sm, id: general, rule: "only screen and (min-width: 768px)"),
  (group: xs, id: general, rule: "only screen and (min-width: 480px)"),

  (group: maxmd, id: general, rule: "only screen and (max-width: 1199px)"),
  (group: maxsm, id: general, rule: "only screen and (max-width: 991px)"),
  (group: maxxs, id: general, rule: "only screen and (max-width: 767px)"),

  (group: print, id: general, rule: "only print"),
  (group: custom, id: screen, rule: "only screen and");


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Header and menu
*/

$header-md-zindex: 650;
$header-xs-zindex: 400;

$submenu-zindex: 610;
$submenu-backdrop-zindex: $submenu-zindex - 10;

$mobile-menu-zindex: 550;
$mobile-menu-backdrop-zindex: $mobile-menu-zindex - 10;


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Sidebar
*/

$sidebarWidthLg: 330px;
$sidebarPaddingLg: 60px 65px 60px 70px;
$contentRightOffsetLg: 60px;
$contentLeftOffsetLg: 68px;

$sidebarWidthMd: 290px;
$sidebarPaddingMd: 45px 50px;
$contentRightOffsetMd: 40px;
$contentLeftOffsetMd: 0;

$sidebarWidthSm: 250px;
$sidebarPaddingSm: 25px 35px;
$contentRightOffsetSm: 20px;
$contentLeftOffsetSm: 0;

$sidebarWidthXs: 100%;
$sidebarPaddingXs: 60px 65px 60px 70px;
$contentRightOffsetXs: 0;
$contentLeftOffsetXs: 0;
