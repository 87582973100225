@charset "UTF-8";

.m-gallery{
  margin: 30px 0;

  &:first-child{
    margin-top: 0;
  }

  &:last-child{
    margin-bottom: 0;
  }
}

.m-gallery_heading{
  font-size: 24px;
  margin: 0 0 25px;
}

.m-gallery_thumb{
  margin-bottom: 15px;

  > a {
    display: block;
    font-size: 0;
  }

  img{
    width: 100%;
    height: auto;
  }
}
