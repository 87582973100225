@font-face {
	font-family: "iconfont";
	src: url('iconfont/iconfont.eot');
	src: url('iconfont/iconfont.eot?#iefix') format('eot'),
		url('iconfont/iconfont.woff2') format('woff2'),
		url('iconfont/iconfont.woff') format('woff'),
		url('iconfont/iconfont.ttf') format('truetype'),
		url('iconfont/iconfont.svg#iconfont') format('svg');
}

@mixin i-icon-styles {
	font-family: "iconfont";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

%i-icon {
	@include i-icon-styles;
}

@function i-icon-char($filename) {
	$char: "";

	@if $filename == 74 {
		$char: "\E001";
	}
	@if $filename == arrow-1-left {
		$char: "\E002";
	}
	@if $filename == arrow-1-right {
		$char: "\E003";
	}
	@if $filename == arrow-2-left {
		$char: "\E004";
	}
	@if $filename == arrow-2-right {
		$char: "\E005";
	}
	@if $filename == arrow-3-right {
		$char: "\E006";
	}
	@if $filename == arrow-down-mini {
		$char: "\E007";
	}
	@if $filename == arrow-down {
		$char: "\E008";
	}
	@if $filename == cross {
		$char: "\E009";
	}
	@if $filename == daw {
		$char: "\E00A";
	}
	@if $filename == eye {
		$char: "\E00B";
	}
	@if $filename == location {
		$char: "\E00C";
	}
	@if $filename == logo-saity {
		$char: "\E00D";
	}
	@if $filename == phone {
		$char: "\E00E";
	}

	@return $char;
}

@mixin i-icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %i-icon;
		} @else {
			@include i-icon-styles;
		}
		content: i-icon-char($filename);
	}
}

.i-icon-74 {
	@include i-icon(74);
}
.i-icon-arrow-1-left {
	@include i-icon(arrow-1-left);
}
.i-icon-arrow-1-right {
	@include i-icon(arrow-1-right);
}
.i-icon-arrow-2-left {
	@include i-icon(arrow-2-left);
}
.i-icon-arrow-2-right {
	@include i-icon(arrow-2-right);
}
.i-icon-arrow-3-right {
	@include i-icon(arrow-3-right);
}
.i-icon-arrow-down-mini {
	@include i-icon(arrow-down-mini);
}
.i-icon-arrow-down {
	@include i-icon(arrow-down);
}
.i-icon-cross {
	@include i-icon(cross);
}
.i-icon-daw {
	@include i-icon(daw);
}
.i-icon-eye {
	@include i-icon(eye);
}
.i-icon-location {
	@include i-icon(location);
}
.i-icon-logo-saity {
	@include i-icon(logo-saity);
}
.i-icon-phone {
	@include i-icon(phone);
}
